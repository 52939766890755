<template>
  <div class="home-search-knowledge">
    <p>知识汇聚</p>
    <template v-if="list && list.length">
      <div class="home-search-knowledge-list">
        <div v-for="item in list" :key="item.id" @click="$openPage(item.url)">
          <div class="home-search-knowledge-title">
            <span>{{ item.newsTypeName }}</span>
            <p>{{ item.title }}</p>
          </div>
          <span>{{ item.summary }}</span>
          <div class="home-search-knowledge-date">
            <span>{{ item.srcSite }}</span>
            <p>
              <span>{{ item.publishTime | moment("YYYY-MM-DD") }}发布</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="home-search-knowledge-page"
        v-if="page.current < page.pageSize"
      >
        <span @click="loadMore">加载更多</span>
      </div>
    </template>
    <div v-else style="padding: 40px 0">
      <a-empty />
    </div>
  </div>
</template>

<script>
import { getIotKnowledgeList } from "@/api/iot";

export default {
  name: "SearchKnowledge",
  props: {
    keywords: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      page: {
        current: 1,
        size: 9,
        pageSize: 1,
      },
    };
  },
  methods: {
    loadData() {
      let { page, keywords } = this;
      let data = {
        queryContents: keywords,
      };
      getIotKnowledgeList(page, data).then((res) => {
        this.list = [...this.list, ...res.records];
        this.page.pageSize = res.pages;
      });
    },
    // 加载跟多
    loadMore() {
      let { page } = this;
      if (page.current === page.pageSize) {
        this.$message.warning("暂无更多内容");
        return;
      }
      page.current++;
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.home-search-knowledge {
  background: #fff;

  > p {
    padding: 0 24px;
    height: 56px;
    font-size: 16px;
    line-height: 56px;
    border-bottom: 1px solid #f0f0f0;
  }

  .home-search-knowledge-list {
    padding: 0 24px;

    > div {
      padding: 24px 0;
      border-bottom: 1px solid #f0f0f0;
      cursor: pointer;

      .home-search-knowledge-title {
        display: flex;
        margin-bottom: 20px;

        > span {
          height: 20px;
          border: 1px solid #ff9999;
          line-height: 18px;
          padding: 0 6px;
          color: #ffa0a0;
          margin-right: 12px;
        }

        > p {
          font-size: 16px;
          line-height: 16px;
          max-width: 740px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      > span {
        color: #8c8c8c;
        line-height: 22px;
        display: block;
        max-height: 44px;
        text-align: justify;
        overflow: hidden;
        margin-bottom: 20px;
      }

      .home-search-knowledge-date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #bfbfbf;

        > span {
          line-height: 14px;
        }

        > p {
          display: flex;
          align-items: center;

          > span {
            line-height: 14px;

            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .home-search-knowledge-page {
    height: 40px;
    text-align: center;
    line-height: 40px;

    > span {
      cursor: pointer;
      color: #EA0B06;
    }
  }
}
</style>
