<template>
  <div class="home-search-banner">
    <div>
      <div class="home-search-input">
        <a-input-search
          style="width: 880px"
          placeholder="请输入搜索名称"
          size="large"
          v-model="value"
          @search="search(value)"
        >
          <template #enterButton>
            <a-button class="btn-red" style="width: 98px">
              <a-icon type="search" style="font-size: 20px" />
            </a-button>
          </template>
        </a-input-search>
      </div>
      <div class="home-search-popular">
        <p>热门搜索</p>
        <div>
          <span
            v-for="(item, index) in list"
            :key="index"
            @click="search(item)"
          >
            {{ item }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchForm",
  props: {
    keywords: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: this.keywords,
      list: ["医疗", "金融", "教育"],
    };
  },
  watch: {
    keywords(val) {
      console.log(val);
      this.value = val;
    },
  },
  methods: {
    search(value) {
      if (!value) {
        this.$message.warning("请输入搜索关键词！");
        return;
      }
      this.$router.push(`/searchInfo?val=${value}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-search-banner {
  height: 212px;
  background: url(../../../assets/img/sst.png) no-repeat;
  background-size: 100% 100%;

  > div {
    width: 1280px;
    margin: 0 auto;
    padding-top: 32px;

    .home-search-input {
      margin-bottom: 16px;
    }

    .home-search-popular {
      width: 880px;

      > p {
        line-height: 12px;
        color: #8c8c8c;
        margin-bottom: 8px;
      }

      > div {
        display: flex;
        flex-wrap: wrap;

        > span {
          height: 32px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          padding: 0 16px;
          font-size: 12px;
          line-height: 30px;
          margin: 8px 8px 0 0;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
