<template>
  <div ref="chart" />
</template>

<script>
import echarts from "@/utils/echarts";

export default {
  name: "EChart",
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    options: {
      handler(val) {
        this.chart.setOption(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = echarts.init(this.$refs.chart);
      this.chart.setOption(this.options);
      window.addEventListener("resize", () => {
        this.chart.resize();
      });
      this.chart.on("click", (params) => {
        this.$emit("chart-click", params);
      });
    });
  },
};
</script>
